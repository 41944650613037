import React from "react";
import { ButtonGroup } from "react-bootstrap";
import User from "./../User";
import Credit from "./../Credit";
import CreditMobile from "./../Credit/Mobile";
import Logo from "./../Logo";
import RealTime from "./../RealTime";

import Wallet from "../../../Components/User/Wallet";
import { isMobile, Event, BRAND } from "../../../../Helper";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      header: null,
      show: true,
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize() {
    if (!isMobile()) {
      this.setState({ header: this.desktop() });
    } else {
      this.setState({ header: this.mobile() });
    }
  }

  collapse = () => {
    this.setState({ show: !this.state.show });
    Event.emit("toggle_sidebar", this.state.show);
  };

  desktop = () => {
    const { t } = this.props;
    return (
      <div className="topbar" id={"topbar"}>
        <div className="container bg-dark-blue">
          <Logo brand={BRAND} />
          <nav className="navbar-custom">
            <ul className="list-unstyled topbar-nav float-right mb-1 ml-auto">
              <li className={"guest-dropdown mt-1"}>
                <Wallet t={t} />
              </li>
              <li className={"guest-dropdown mt-1"}>
                <Credit t={t} />
              </li>
              <li className={"guest-dropdown mt-1"}>
                <ul>
                  <User t={t} />
                  {/* <Notifications t={t} /> */}
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    );
  };

  mobile = () => {
    const { t } = this.props;
    return (
      <div className="topbar" id={"topbar"}>
        <Logo brand={BRAND} />
        <nav className="navbar-custom">
          <ul className="list-unstyled topbar-nav float-right mb-0 mr-1">
            <li className={"guest-dropdown mt-1"}>
              <Wallet t={t} />
            </li>
            <RealTime t={t} />
            <CreditMobile t={t} />
            <ButtonGroup>
              <User t={t} />
            </ButtonGroup>
          </ul>
        </nav>
      </div>
    );
  };

  render() {
    return this.state.header;
  }
}

export default Header;
