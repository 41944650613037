import React from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Event } from "../../../../Helper";

export default class Help extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  componentDidMount() {
    Event.on("show_help", () => {
      this.setState({ show: true, effect: "pulse" });
    });
  }

  showPrivacy = (e) => {
    e.preventDefault();
    this.setState({ show: true, effect: "pulse" });
  };

  closePrivacy = () => {
    this.setState({ show: false, effect: "zoomOut" });
  };

  render() {
    return (
      <>
        <Link className="text-left" to={"#"} onClick={this.showPrivacy}>
          <i className="mdi mdi-help-circle-outline align-middle mr-1"></i>
          <span className={"op-8 how-to-play"}>How to play</span>
        </Link>
        <Modal
          size="lg"
          centered={true}
          backdrop={"static"}
          show={this.state.show}
          onHide={this.closePrivacy}
          aria-labelledby="general-lg-modal"
          className={"animated " + this.state.effect}
        >
          <Modal.Header className={"font-light"}>
            Help on how play
            <button
              type="button"
              className="close p-2"
              onClick={this.closePrivacy}
            >
              <i className={"mdi mdi-close"} />
            </button>
          </Modal.Header>
          <Modal.Body className={"modal-reader"}>
            <h2>How to Register</h2>
            Click on Register/Login Add your phone number
            <br />
            Create your username
            <br />
            Input your password
            <br />
            Click on Register
            <br />
            Proceed to access the site with ease.
            <br />
            <h2>How to Play</h2>
            Enter your bet amount and click BET.
            <br />
            Once the graph starts rising, click on Cash out @ x to cash out
            manually.
            <br />
            If the graph busts before you cash out and before crossing your
            selected x factor, you lose.
            <br />
            <h2>Bet Amount</h2>
            This is the amount you want to Bet with, e.g. Ksh10, Ksh100, Ksh500,
            Ksh800.
            <h2>Profit</h2>
            The extra amount you make on top of your Stake
            <br />
            Win Chance
            <br />
            The probability of winning in relation to the Auto Cash out value.
            <h2>How to Deposit</h2>
            Click on deposit.
            <br />
            Enter the Amount and click on Lipa Na M-Pesa Online to wait for a
            prompt message.
            <br />
            Enter your M-pesa pin on the STK push send to your registered line.
            <br />
            NB: You can use this method to deposit from a different number from
            the one you are registered with.
            <h2>How to Withdraw</h2>
            Click on the withdraw tab on the site,
            <br />
            Enter Amount and click withdraw.
            <br />
            20% of your winning amount is subject to Withholding Tax as dictated
            by the Income Tax Act
            <br />
            For more enquiries; 0757 040 141
            <br />
            Gaming can be addictive – Strictly for persons of 18 years and
            above. Play Responsibly
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
