import io from "socket.io-client";
import { SOCKET, Event, sendNotfication, DEVELOPMENT } from "./Helper";

/**
 * Socket URL
 */
const ws = SOCKET;

/**
 * Initialize Socket Connection
 */

const socket = io.connect(ws, {
  withCredentials: false,
  extraHeaders: {
    "my-custom-header": "abcd",
  },
});

/*
 * Auto Login By Reload
 * TODO
 */
socket.on("connect", () => {
  Event.emit("connect");
});

/*
 * Disconnect Server
 */
socket.on("disconnect", () => {
  Event.emit("disconnect");
  var data = {
    time: 0,
  };
  Event.emit("waiting_crash", data);
  sendNotfication(
    "Connection Lost, Trying to connect...",
    "danger",
    "top-center"
  );
});

export default socket;
