import React from "react";
import Login from "../../Auth/Login";
import Logo from "./../Logo";
import Register from "../../Auth/Register";
import { Event, BRAND } from "../../../../Helper";
import Wallet from "../../../Components/User/Wallet";

var show = true;

class Header extends React.Component {
  collapse = () => {
    show = !show;
    Event.emit("toggle_sidebar", show);
  };
  render() {
    const { t } = this.props;
    return (
      <div className="topbar" id={"topbar"}>
        <div className="container bg-dark-blue">
          <Logo brand={BRAND} />
          <nav className="navbar-custom">
            <ul className="list-unstyled topbar-nav float-right mb-0 ml-auto">
              <li className={"guest-dropdown mt-1"}>
                <Wallet t={t} />
              </li>
              <li className={"guest-dropdown mt-1"}>
                <Login t={t} />
              </li>
              <li className={"guest-dropdown mt-1"}>
                <Register t={t} />
              </li>
            </ul>
          </nav>
        </div>
      </div>
    );
  }
}
export default Header;
