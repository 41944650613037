import React from 'react'
import { Table } from "react-bootstrap";
import storage from "../../../Storage";
import {encode, decode, wait, fixDate} from "../../../Helper";
import C from "../../../Constant";
import socket from "../../../Socket";

export default class Affiliate extends React.Component { 
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            list: [],
            totalEarned: 0,
        };
    }

    componentDidMount() {
        wait(1700).then(() => {
            this.setState({ loading: false });
            socket.emit(C.MY_AFF, encode({token: storage.getKey('token')}));

        })
        socket.on(C.MY_AFF, data => this.makeList(data));
    }

    makeList = (data) => {
        this.setState({ loading: false, list: data.affliates, totalEarned: data.totalEarned });
    }

    render(){

        const list = Array.isArray(this.state.list) ? this.state.list.map((row, i) => <List key={i} row={row} />) : [];
        let { totalEarned } = this.state;

        return(
            <>
            <div>
                <p className={'text-center text-upper text-white'}>Total Earned: KES {totalEarned}</p>
            </div>
            <div className="table-responsive">
                {
                    this.state.loading ?
                        <div className="text-center">
                            <div class="spinner-border text-info my-3" role="status"/>
                        </div>
                    :
                    <>
                        {list.length === 0 ?
                            <>
                                You have not introduced anyone yet
                            </>
                            :
                            <Table className={"mb-2"}>
                                <thead>
                                    <tr>
                                        <th>
                                               #
                                        </th>
                                        <th>
                                            Username
                                        </th>
                                        <th>
                                            Earned
                                        </th>
                                        <th>
                                            Date
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {list}
                                </tbody>
                            </Table>
                        }
                    </>
                }
            </div>
            </>
        );
    }
}



class List extends React.Component {
    render(){
        const { row } = this.props;
        return(
            <tr>
                <td>
                    {row.id} {/* Or any unique identifier */}
                </td>
                <td>
                    {row.user_name}
                </td>
                <td>
                 KES {row.earned_amount}
                </td>
                <td>
                     {fixDate(row.created_at)}
                </td>
            </tr>
        );
    }
}