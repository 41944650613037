import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import {
  isEmail,
  decode,
  encode,
  sendNotfication,
  wait,
  isPhoneValid1,
  isPhoneValid2,
} from "../../../Helper";
import socket from "../../../Socket";
import C from "../../../Constant";
import Reset from "./Reset";

class Forget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      loading: true,
      resetPage: false,
      disabled: false,
      showed: true,
      email: "",
    };
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentWillMount() {
    wait(500).then(() => {
      this.setState({ loading: false });
    });
  }

  componentDidMount() {
    socket.on(C.RESET_CODE, (data) => this.resetPassword(decode(data)));
  }

  handleShow(e) {
    this.props.clicked();
    this.setState({ show: true });
  }

  handleClose() {
    this.setState({ show: false });
  }

  resetPass = (e) => {
    this.setState({ resetPage: !this.state.resetPage });
    this.setState({ showed: !this.state.showed });
  };

  resetPassword = (data) => {
    let response = data;

    const { t } = this.props;

    this.setState({ disabled: false });

    if (response.status) {
      //  this.resetPass();
      return sendNotfication(
        t("your reset code send to mobile_number"),
        "success",
        "top-center"
      );
    } else {
      return sendNotfication(
        t("this_email_is_not_registred_on_our_system"),
        "warning",
        "top-center"
      );
    }
  };

  submitForm(e) {
    e.preventDefault();
    const { t } = this.props;

    if (!(isPhoneValid1(this.state.email) || isPhoneValid2(this.state.email))) {
      return sendNotfication(
        t("please enter valid mobile number"),
        "warning",
        "top-center"
      );
    }

    this.setState({ disabled: true });

    wait(700).then(() => {
      socket.emit(C.RESET_PASSWORD, encode({ email: this.state.email }));
    });
  }

  back = () => {
    this.props.clicked();
  };

  render() {
    let { t } = this.props;
    return (
      <>
        {this.state.loading && !this.state.resetPage ? (
          <>
            <div className="text-center">
              <div
                class="spinner-border text-info my-3 user-loader"
                role="status"
              />
            </div>
          </>
        ) : (
          <>
            <div
              class="px-3"
              style={
                this.state.showed ? { display: "block" } : { display: "none" }
              }
            >
              <div className="text-center auth-logo-text">
                <p className="mt-2 text-white">
                  Enter Your Phone Number to Reset Password
                </p>
              </div>
              <form className="my-4" onSubmit={(e) => this.submitForm(e)}>
                <div className="form-group text-center">
                  <div className="form-group">
                    <div className="input-group">
                      <div className="input-group-append">
                        <span className="input-group-text bgp">Phone No</span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        name="email"
                        placeholder={"O7XX"}
                        autoComplete={"off"}
                        style={{ height: 40 }}
                        onChange={(e) =>
                          this.setState({ email: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group mt-2 text-center">
                  <button
                    type="submit"
                    className="btn btn-bet no-shadow btn-block"
                    disabled={this.state.disabled}
                  >
                    <i className="mdi mdi-email" /> Reset
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm -2 btn-outline-light btn-block"
                    onClick={this.back}
                  >
                    <i className="mdi mdi-refresh" /> Back
                  </button>
                </div>
              </form>
            </div>
          </>
        )}
        {this.state.resetPage && <Reset t={t} clicked={this.resetPass} />}
      </>
    );
  }
}

export default Forget;
