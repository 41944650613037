import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as Cookies from "js-cookie";
import UserHeader from "./Parts/Header/Header-User";
import GuestHeader from "./Parts/Header/Header-Guest";
//import Sidebar from "./Parts/Sidebar";
//import Footer from "./Parts/Footer";
import socket from "../../Socket";
import { Event, decode, encode, wait, isMobile } from "../../Helper";
import WalletAlert from "../../App/Components/User/Wallet/Alert";
import C from "../../Constant";
import Login from "./Auth/Login";
import storage from "../../Storage";
import Help from "./Parts/Sidebar/Help";
import Engine from "../Games/Crash/Engine";
import Canvas from "../Games/Crash/Graphic/";
import Mobi from "../Games/Crash/Graphic/Mobi";
import Bet from "../Games/Crash/Bet";
import Queue from "../Games/Crash/Queue";
import HistoryList from "../Games/Crash/includes/HistoryList";
import { Link } from "react-router-dom";


class Index extends React.Component {
  _Mounted = false;
  constructor(props) {
    super(props);
    this.state = {
      mobile: false,
      isLogged: false,
      token: false,
      header: <GuestHeader t={this.props.t} location={this.props.location} />,
      auth: false,
      activeBunner: "/assets/images/banners/banner2.jpg",
    };
    this.handleResize = this.handleResize.bind(this);
  }
  componentWillMount() {
    wait(500).then(() => {
      this._Mounted = true;
    });
  }

  componentDidMount() {
    socket.on(C.ONLINE, (status) => this.loginUser(decode(status)));
    Event.on("showAuthModal", (status) => this.activeAuth(status));

    /**
     * Initialize Authentication
     */
    const sessionCookie = Cookies.get("auth");

    if (storage.getKey("token") !== null && sessionCookie) {
      socket.emit(
        C.ONLINE,
        encode({
          jwt_token: storage.getKey("jwt_token"), //fake
          user_token: storage.getKey("user_token"), //fake
          security_key: storage.getKey("security_key"), //fake
          secret_user: storage.getKey("secret_user"), //fake
          secret_realtime: storage.getKey("secret_realtime"), //fake
          client_system: storage.getKey("client_system"), //fake
          token_key: storage.getKey("token_key"), //fake
          secret_token: storage.getKey("secret_token"), //fake
          token: storage.getKey("token"), // REAL
        })
      );
    }
    wait(600).then(() => {
      this.handleResize();
    });
    window.addEventListener("resize", this.handleResize);

    if (storage.getKey("token")) {
      this.setState({ isLogged: true, token: storage.getKey("token") });
    }

    wait(500).then(() => {
      Engine.getStatus();
    });
    //switch bunner
    this.intervalId = setInterval(() => {
      if (this.state.activeBunner === "/assets/images/banners/banner2.jpg") {
        this.setState({ activeBunner: "/assets/images/banners/banner1.jpg" });
      } else {
        this.setState({ activeBunner: "/assets/images/banners/banner2.jpg" });
      }
    }, 2500);
    //const playersPerfectScrollar = new PerfectScrollbar('.players-scrollbar');
    //playersPerfectScrollar.update();
  }

  activeAuth = (status) => {
    this.setState({ auth: status });
  };

  loginUser = (data) => {
    wait(500).then(() => {
      console.log("Log in user data", data);
      if (data.status === true) {
        this.setState({
          header: (
            <UserHeader t={this.props.t} location={this.props.location} />
          ),
        });
        Cookies.set("uid", data.id, { expires: 14 });
        Cookies.set("auth", true, { expires: 14 });
        // storage.setKey('name', data.name);
        // storage.setKey('email', data.email);
        // storage.setKey('phoneNumber', data.phoneNumber);
        // storage.setKey('credit', data.credit);
        // storage.setKey('avatar', data.avatar);
        // storage.setKey('friends', data.friends);
        // storage.setKey('room', data.room);
      } else {
        wait(7000).then(() => {
          localStorage.clear();
        });
      }
    });
  };
  getWidth() {
    return document.documentElement.clientWidth || document.body.clientWidth;
  }
  handleResize() {
    if (this.getWidth() < 1540) {
      this.setState({ col: "col-xl-12" });
      Event.emit("hide_games");
    } else {
      this.setState({ col: "col-xl-9" });
      Event.emit("show_min_games");
    }

    if (isMobile()) {
      this.setState({ mobile: true });
    } else {
      this.setState({ mobile: false });
    }

    if (isMobile()) {
      this.setState({ padding: "p-0", ovh: "ovh" });
    }
  }
  render() {
    //let { header, auth, mobile } = this.state;
    let { header, auth } = this.state;
    let { content } = this.props; // Pages / Games Contents
    let wallet; // Show Wallet if User don't Have Cash

    try {
      wallet = this.props.get_wallet.show;
    } catch (e) {}

    const { t } = this.props;
    return (
      <>
        {header}
        <div className="page-wrapper">
          <div className="page-content container py-4" id={"page-content"}>
            <div className=" d-flex justify-content-between container">
              <div className="t-row-wrap">
                <Help t={t} />
                <span className="fontt-10">
                  <a href="tel:+254757040141">
                    <i className="mdi mdi-phone align-middle mr-1 ml-2"></i>
                    <span className={"op-8 how-to-play"}>0757 040 141</span>
                  </a>
                </span>
              </div>
              <div className="text-light payb-txt"></div>
            </div>
            <div className="content-section mb-1">
              <div className="row m-0 p-1">
                <div className="col-md-12 m-0 p-2">
                  {content}
                </div>
              </div>
              <div className="row pt-2 pb-3 pr-2 pl-2">
                <div className="col-md-4 mt-2">
                  <div className="footer-card">
                    <ul className="no-style-list">
                      <li>
                        <a href="tel:+254757040141">
                          <img
                            src="/assets/images/icons/phone-solid.svg"
                            className="btn-icon footer-icon-color"
                          ></img>{" "}
                          Call +254757040141{" "}
                        </a>
                      </li>
                      <li>
                        <a href="https://wa.me/+254757040141?text=Hello">
                          <img
                            src="/assets/images/icons/whatsapp.svg"
                            className="btn-icon footer-icon-color"
                          ></img>{" "}
                          WhatsApp +254757040141
                        </a>
                      </li>
                      <li>
                        <a href="mailto: customercare@chomoagame.co.ke">
                          <img
                            src="/assets/images/icons/envelope-regular.svg"
                            className="btn-icon footer-icon-color"
                          ></img>{" "}
                          Email customercare@chomoagame.com{" "}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4 mt-2">
                  <div className="footer-card text-center">
                    <img
                      src="/assets/images/logo2.png"
                      className="logo"
                      alt="Logo"
                    />{" "}
                    <br></br>
                    <div>&copy; 2023</div>
                    <div>All Rights Reserved</div>
                    <div>
                      {" "}
                      <a href="#">FAQs</a> | <a href="#">Terms</a> | <Link to="/affiliate"> Affiliate</Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mt-2">
                  <div className="footer-card  text-center">
                    <img
                      src="/assets/images/18.png"
                      className="logo-smm ulogo"
                      alt="Logo"
                    />{" "}
                    <br></br>
                    <div>
                      GAMBLING CAN BE ADDICTIVE. PLEASE PLAY RESPONSIBLY
                    </div>
                    <div>
                      Licenced by the Betting Control and Licencing
                      Board (BCLB) under licence number #0000100
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       
      </>
    );
  }
}

Index.propTypes = {
  get_wallet: PropTypes.string,
};

const mapStateToProps = (state) => ({
  get_wallet: state.items.get_wallet,
});

export default connect(mapStateToProps, null)(Index);
