import React from 'react'
import { Modal, Row, Col, Card } from "react-bootstrap";
import {Helmet} from "react-helmet";
import storage from "../../../Storage";
import {SITE} from "../../../Helper";
import Terms from "./Terms";
import List from "./List";

export default class Affiliate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            referralId: null,
            token: storage.getKey('token') ? storage.getKey('token') : null,
            logged: false
        };
    }

    handleCopyLink = () => {
        // Construct the full URL to be copied
        const textToCopy = SITE + '?referral=' + this.state.referralId;
        // Use the Clipboard API to write text to the clipboard
        navigator.clipboard.writeText(textToCopy)
        .then(() => {
        console.log('Text copied to clipboard:', textToCopy);
        alert('Copied to clipboard!');
        })
        .catch(err => {
        console.error('Failed to copy text: ', err);
        });
    };

    componentDidMount(){
        function getCookie(name) {
            const value = `; ${document.cookie}`; // Add a leading semicolon and space for easier parsing
            const parts = value.split(`; ${name}=`); // Split the cookies on the desired cookie name
            if (parts.length === 2) { // If the cookie is found
                return parts.pop().split(';').shift(); // Return the cookie value
            }
            return null; // Return null if the cookie is not found
        }
        this.setState({ referralId:  getCookie('uid') });

    }

    componentWillMount() {
        if(this.state.token !== null){
            this.setState({ logged: true });
        }
    }

    render(){
        let { logged } = this.state;

        return(
            <>
                <Helmet>
                    <title>Affiliate - ChomoaGame</title>
                </Helmet>
                <div className={'p-5 wheel-content'}>
                    <Terms/>
                    <h2 className={'text-center text-upper footer-icon-color font-weight-bold'}>Affiliate</h2>
                    <p className={'text-center text-upper text-white'}>
                        Invite your friends to get free coins.
                    </p>
                    <Row>
                        <Col sm={12} xl={5} md={5} className={'m-auto'}>
                            <Card>
                                <Card.Body className={'rounded text-center'} style={{ background: "#e45e25" }}>
                                    <label className="text-white text-upper" onClick={this.handleCopyLink}>Your referal Link click to coppy</label>
                                    { logged ?

                                        <input type="text" onClick={this.handleCopyLink} value={SITE + '?referral=' + this.state.referralId}  className={'form-control'} style={{ background: "#2F3138" }} />
                                        :
                                        <input type="text" value={"Please login to see your link."} className={'form-control'} style={{ background: "#2F3138" }} />
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
                    <Row>
                        <Col sm={12} xl={12} md={12} className={'mx-auto mt-3'}>
                            <Card>
                                <Card.Body>
                                    { !logged ?
                                        <div className="text-center">[ Login to see your stats ]</div>
                                        :
                                        <List/>
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
            </>
        );
    }
}